import React, { useState, useEffect } from 'react';

import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Switch from 'react-switch';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import 'moment/min/locales';

import 'bootstrap4-duallistbox';
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';

import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Timezone from '../../commons/timezone/Timezone.js';
import * as Utils from '../../commons/utils/Utils';
import Security from '../../commons/security/Security.js';
import backendStrings from '../../lang/backendStrings.js';
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js';
import * as ConstantsMedidor from '../unidadMedidor/constants.js';
import LlantaBasesSelect from './LlantaBasesSelect.jsx';

const FORM_DATA_INIT = {
  serial: '',
  medida: '',
  estado: null,
  pais: null,
  clase: null,
  banda: null,
  desgaste: 0,
  odometro: 0,
  titular: '',
  llanta_marca: null,
  llanta_modelo: null,
  recapadas: null,
  fechaAlta: null,
  precioCompra: null,
  fechaVenc: null,
  movil: null,
  posicion: '',
  eje_flotante: null,
  base: null,
  posicion: null,
  unidad_medidor_id: null,
};
const MySwal = withReactContent(Swal);

const LlantasAbm = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const intl = useIntl();
  const [formData, setFormData] = useState({ ...FORM_DATA_INIT });

  const [repuestoSwitch, setRepuestoSwitch] = useState(false);
  const [ejeSwitch, setEjeSwitch] = useState(false);
  const [estados, setEstados] = useState([]);
  const [clases, setClases] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [bandas, setBandas] = useState([]);
  const [recapados, setRecapados] = useState([]);
  const [bases, setBases] = useState([]);
  const [bandaRequerida, setBandaRequerida] = useState(true);
  const [errors, setErrors] = useState([]);

  const requiredSymbol = props.action !== 'VIEW' ? ' *' : '';

  useEffect(() => {
    if (
      (Security.hasPermission('LLANTAS_CREAR') && props.action === 'ADD') ||
      (Security.hasPermission('LLANTAS_MODIFICAR') && props.action === 'EDIT') ||
      (Security.hasPermission('LLANTAS_VISUALIZAR') && props.action === 'VIEW')
    ) {
      initForm();
    } else {
      setRedirectTo('/error');
    }
  }, []);

  const initForm = (params) => {
    Promise.all([
      null,
      null,
      ConfigBusiness.get('llantas.bandas'),
      Utils.getData('/llantamodelos/modelos'),
      Utils.getData('/llantamarcas/marcas'),
      ConfigBusiness.get('llantas.estadoAsignada'),
      ConfigBusiness.get('llantas.estadoEnTaller'),
      ConfigBusiness.get('llantas.estadoEnRecapado'),
      ConfigBusiness.get('llantas.estadoAlmacen'),
      ConfigBusiness.get('llantas.estadoBaja'),
      Utils.getData('/bases/select-filtered-by-user/base/10'),
      Utils.getData('/llanta-modelo-clases'),
    ])
      .then((data) => {
        let bandas = data[2] ? data[2].split(',').map((e) => ({ id: e, nombre: e })) : [];

        let estadoAsignada = data[5] ? data[5].split(',') : null;
        let estadoEnTaller = data[6] ? data[6].split(',') : null;
        let estadoEnRecapado = data[7] ? data[7].split(',') : null;
        let estadoAlmacen = data[8] ? data[8].split(',') : null;
        let estadoBaja = data[9] ? data[9].split(',') : null;

        let estados = [
          ...estadoAsignada,
          ...estadoEnTaller,
          ...estadoEnRecapado,
          ...estadoAlmacen,
          ...estadoBaja,
        ];
        setBandas(bandas);
        setModelos(data[3]);
        setMarcas(data[4]);
        setEstados(estados);
        setBases(data[10].map((el) => ({ id: el.value, descripcion: el.label })));
        setClases(data[11]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        (props.action === 'VIEW' || props.action === 'EDIT') && fetchFormData();
      });
  };

  const fetchFormData = () => {
    Utils.getData(`/llantas/${props.match.params.id}`).then(async (res) => {
      setFormData({
        ...res,
        movil: res.movil ? await getMovil(res.movil.id) : null,
        posicion: res.llanta_posicion,
        banda: { id: res.banda, nombre: res.banda },
        recapadas: { value: res.recapadas, label: res.recapadas },
        eje_flotante: res.llanta_posicion
          ? res.llanta_posicion.tipo_posicion.descripcion === 'FLOTANTE'
            ? true
            : false
          : false,
      });
      setBandaRequerida(res.recapadas === 0 ? false : true);
      setEjeSwitch(
        res.llanta_posicion
          ? res.llanta_posicion.tipo_posicion.descripcion === 'FLOTANTE'
            ? true
            : false
          : false,
      );
    });
  };

  const getMovil = (id) => {
    return Utils.getData(`/moviles/${id}`).then((m) => m);
  };

  const handleInputFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };
  const handleSelectFormChange = (e, name) => {
    let formDataCopy = { ...formData };
    switch (name) {
      case 'llanta_marca':
        formDataCopy[name] = e;
        if (e) getModelosDeMarca(e.id);
        else {
          formDataCopy['llanta_modelo'] = null;
          formDataCopy['recapadas'] = null;
          setRecapados([]);
        }
        formDataCopy['llanta_modelo'] = null;
        formDataCopy['recapadas'] = null;
        break;
      case 'llanta_modelo':
        formDataCopy[name] = e;
        if (e) {
          formDataCopy['clase'] = e.clase;
          setRecapados(
            Array(e.recapado + 1)
              .fill(0)
              .map((_, i) => ({ value: i, label: i })),
          );
        } else {
          formDataCopy['recapadas'] = null;
          setRecapados([]);
        }
        break;
      case 'base':
        e && getBase(e.id);
        !e && (formDataCopy[name] = e);
        break;
      case 'posicion':
        formDataCopy[name] = e;
        e && e.tipo_posicion.descripcion === 'FLOTANTE' ? setEjeSwitch(true) : setEjeSwitch(false);
        break;
      case 'movil':
        if (e) {
          formDataCopy[name] = { ...e, tipologiaPosicion: e.tipologia_posicion };
          e && (formDataCopy['clase'] = e.clase);
          e && (formDataCopy['unidad_medidor_id'] = e.unidad_medidor_id);
          e &&
            (formDataCopy['base'] = e.base) &&
            MySwal.fire(
              intl.formatMessage({
                id: 'llantasAbm.asignacion_base_del_movil_a_llanta',
                defaultMessage: 'Se le asigno la base del movil',
              }),
              '',
              'success',
            );
        }
        break;
        case 'fechaAlta':
        case 'fechaVenc':
          let fecha = moment(e);
          if (!fecha.isValid()) {
            formDataCopy[name] = null;
          }else{
            formDataCopy[name] = fecha.format('YYYY-MM-DD HH:mm:ss');
          }
        break;
      case 'recapadas':
        formDataCopy[name] = e;
        if(e == null || e.value===0) {
          formDataCopy['banda'] = null;
        }
        if (e == null) {
          setBandaRequerida(true);
        }else{
          if(e.value===0)
            setBandaRequerida(false);
          else
            setBandaRequerida(true);
        }
        break;
      default:
        formDataCopy[name] = e;
        break;
    }
    setFormData(formDataCopy);
  };
  const getBackendMoviles = (search) => {
    return Utils.getData(`/moviles/simple-search?search=${search}`).then((res) => ({
      options: res,
      complete: true,
    }));
  };
  const handleEjeSwitchChange = (value) => {
    setEjeSwitch(value);
    let formDataCopy = { ...formData };
    formDataCopy['eje_flotante'] = value ? 1 : 0;
    setFormData(formDataCopy);
  };

  const getModelosDeMarca = (id) => {

    Utils.getData(`/llantamodelos/modelosdemarca/${id}`)
      .then((res) => setModelos(res))
      .catch((err) => console.log(err));
  };

  const getBase = (id) => {
    Utils.getData(`/bases/${id}`).then((res) => setFormData({ ...formData, base: res }));
  };

  const checkAvailable = (e) => {
    const value = e.target.value;
    if (value) {
      Utils.getData(`/llantas/checkAvailable/${value}`)
        .then(() => setErrors([]))
        .catch((err) => setErrors(err.response.data.detalle));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formDataCopy = { ...formData };
    formDataCopy.fechaAlta = Timezone.getDateForServer(formData.fechaAlta);
    formDataCopy.fechaVenc = Timezone.getDateForServer(formData.fechaVenc);
    Utils.sendData(
      `/llantas${props.action === 'ADD' ? '' : '/' + formData.id}`,
      props.action === 'ADD' ? 'POST' : 'PUT',
      formDataCopy,
    )
      .then(() => {
        setRedirectTo('/llantas');
      })
      .catch((err) => {
        setErrors(err.response.data.detalle);
      });
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="row">
        <div className="col-md-12">
          <div className="alert alert-danger" role="alert" hidden={errors.length === 0}>
            {errors.map((e, i) => (
              <li key={i}>
                <FormattedMessage
                  {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}
                />
              </li>
            ))}
          </div>
          <form className="form form-horizontal" onSubmit={handleSubmit}>
            <div className="form-body">
              <div className="card pull-up">
                <div className="card-content">
                  <div className="card-body">
                    <h4 className="form-section">
                      <i className="la la-info-circle"></i>{' '}
                      <FormattedMessage
                        id="llantasAbm.render.general_data.header_datos_generales"
                        defaultMessage="Datos Generales"
                      />{' '}
                      <div className="float-right" style={{ fontSize: '14px' }}>
                        *{' '}
                        <FormattedMessage
                          id="llantasAbm.render.general_data.campos requeridos"
                          defaultMessage="campos requeridos"
                        />
                      </div>
                    </h4>
                    <div className="row">
                    {(ConfigBusiness.get('llantas.serial.autoasignacion.habilitado') === "false") || (formData.serial !== "") ? 
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="serial">
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_serial"
                              defaultMessage="Serial/SN"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.serial ? formData.serial : ''}
                              </div>
                            ) : (
                              <div>
                                {(ConfigBusiness.get('llantas.serial.autoasignacion.habilitado') === "false") ?
                                <input                           
                                  type="text"
                                  className="form-control"
                                  id="serial"
                                  name="serial"
                                  placeholder={intl.formatMessage({
                                    id: 'llantasAbm.render.general_data.placeholder_serial',
                                    defaultMessage: 'Requerido',
                                  })}
                                  value={formData.serial}
                                  onChange={handleInputFormChange}
                                  onBlur={checkAvailable}
                                /> : 
                                  <input                           
                                  type="text"
                                  className="form-control"
                                  id="serial"
                                  readOnly
                                  name="serial"
                                  placeholder={intl.formatMessage({
                                    id: 'llantasAbm.render.general_data.placeholder_serial',
                                    defaultMessage: 'Requerido',
                                  })}
                                  value={formData.serial}
                                  //onChange={handleInputFormChange}
                                  //onBlur={checkAvailable}
                                  /> }
                              </div>
                            )}
                          </div>
                        </div>
                      </div> : null}
                      <div className="col-md-6">
                        {props.action === 'ADD' 
                        ? ''
                        : <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="estado">
                              <FormattedMessage
                                id="llantasAbm.render.general_data.label_estado"
                                defaultMessage="Estado"
                              /> :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">
                                {formData.estado}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="row">
                      {/* MARCA */}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label text-nowrap"
                            htmlFor="marca"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_marca"
                              defaultMessage="Marca"
                            /> *:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ||
                            (props.action === 'EDIT' &&
                              !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.llanta_marca ? formData.llanta_marca.nombre : ''}
                              </div>
                            ) : (
                              <div>
                                <Select
                                  id="llanta_marca"
                                  name="llanta_marca"
                                  placeholder={intl.formatMessage({
                                    id: 'movilesAbm.render.general_data.placeholder_marca',
                                    defaultMessage: 'Seleccione',
                                  })}
                                  options={marcas}
                                  valueKey="id"
                                  labelKey="nombre"
                                  disabled={props.action === 'EDIT' || props.action === 'VIEW'}
                                  onChange={(e) => handleSelectFormChange(e, 'llanta_marca')}
                                  value={formData.llanta_marca}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* DISEÑO */}
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label text-nowrap"
                            htmlFor="modelo"
                          >
                            <FormattedMessage
                              id="movilesAbm.render.general_data.label_disenio"
                              defaultMessage="Diseño"
                            />
                            {ConfigBusiness.get('moviles.modelo.obligatorio') === 'true'
                              ? requiredSymbol
                              : ' '}
                            :
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ||
                            (props.action === 'EDIT' &&
                              !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.llanta_modelo && formData.llanta_modelo.nombre
                                  ? formData.llanta_modelo.nombre
                                  : ''}
                              </div>
                            ) : (
                              <div>
                                <Select
                                  id="llanta_modelo"
                                  name="llanta_modelo"
                                  placeholder={
                                    formData.llanta_marca
                                      ? intl.formatMessage({
                                          id: 'llantasAbm.render.general_data.placeholder_modelo',
                                          defaultMessage: 'Seleccione',
                                        })
                                      : ''
                                  }
                                  options={modelos.map((item) => ({
                                    ...item,
                                    nombre: item.nombre + ' - ' + item.llanta_medida.nombre,
                                  }))}
                                  valueKey="id"
                                  labelKey="nombre"
                                  onChange={(e) => handleSelectFormChange(e, 'llanta_modelo')}
                                  value={formData.llanta_modelo}
                                  disabled={!formData.llanta_marca || (props.action === 'EDIT' || props.action === 'VIEW')}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ANOTHER ROW */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="medida">
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_medida"
                              defaultMessage="Medida"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            <div className="form-control-static col-form-label form-value">
                              {formData.llanta_modelo &&
                                formData.llanta_modelo.llanta_medida.nombre}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="or">
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_recapados"
                              defaultMessage="O/R"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.recapadas ? formData.recapadas.value : ''}
                              </div>
                            ) : (
                              <div>
                                <Select
                                  id="recapadas"
                                  name="recapadas"
                                  placeholder={
                                    formData.llanta_modelo
                                      ? intl.formatMessage({
                                          id: 'llantasAbm.render.general_data.placeholder_recapaados',
                                          defaultMessage: 'Seleccione',
                                        })
                                      : ''
                                  }
                                  options={recapados}
                                  valueKey="value"
                                  labelKey="label"
                                  onChange={(e) => handleSelectFormChange(e, 'recapadas')}
                                  value={formData.recapadas}
                                  disabled={!formData.llanta_modelo || props.action === 'EDIT'}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ANOTHER ROW */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="clase">
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_clase"
                              defaultMessage="Clase"
                            />
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.clase ? formData.clase.nombre : ''}
                              </div>
                            ) : (
                              <Select
                                id="clase"
                                name="clase"
                                placeholder={intl.formatMessage({
                                  id: 'llantasAbm.render.general_data.placeholder_clase',
                                  defaultMessage: 'Seleccione',
                                })}
                                options={clases}
                                valueKey="id"
                                labelKey="nombre"
                                onChange={(e) => handleSelectFormChange(e, 'clase')}
                                value={formData.clase}
                                disabled={
                                  (formData.movil &&
                                    (props.action === 'EDIT' || props.action === 'ADD')) ||
                                  props.action === 'VIEW'
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label"
                            htmlFor="titular"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_titular"
                              defaultMessage="Titular"
                            />{requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.titular ? formData.titular : ''}
                              </div>
                            ) : (
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="titular"
                                  name="titular"
                                  placeholder={intl.formatMessage({
                                    id: 'llantasAbm.render.general_data.placeholder_titular',
                                    defaultMessage: 'Titular',
                                  })}
                                  value={formData.titular}
                                  onChange={handleInputFormChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="tipo">
                            <FormattedMessage
                              id="llantasAbm.render.general_data.Unidad_medidor"
                              defaultMessage="Unidad Medidor"
                            />:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.unidad_medidor_id
                                  ? ConstantsMedidor.TIPOS_UNIDAD_MEDIDOR[
                                      formData.unidad_medidor_id
                                    ].label
                                  : ''}
                              </div>
                            ) : (
                              <SelectWithIcons
                                placeholder="Tipo de llanta"
                                valueKey="id"
                                disabled={props.action === 'VIEW'}
                                labelKey="label"
                                clearable={false}
                                onChange={(e) => handleSelectFormChange(e, 'unidad_medidor_id')}
                                value={formData.unidad_medidor_id}
                                options={Object.values(ConstantsMedidor.TIPOS_UNIDAD_MEDIDOR)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="banda">
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_banda"
                              defaultMessage="Banda"
                            />{bandaRequerida ? requiredSymbol : ''}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.banda && formData.banda.nombre}
                              </div>
                            ) : (
                              <div>
                                <Select
                                  id="banda"
                                  name="banda"
                                  placeholder={
                                    formData.banda
                                      ? intl.formatMessage({
                                          id: 'llantasAbm.render.general_data.placeholder_banda',
                                          defaultMessage: 'Seleccione',
                                        })
                                      : ''
                                  }
                                  options={bandas}
                                  valueKey="id"
                                  labelKey="nombre"
                                  onChange={(e) => handleSelectFormChange(e, 'banda')}
                                  disabled={!formData.recapadas || formData.recapadas.value === 0}
                                  value={formData.banda}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label"
                            htmlFor="precioCompra"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_precioCompra"
                              defaultMessage="Precio de Compra"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.precioCompra ? formData.precioCompra : ''}
                              </div>
                            ) : (
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="precioCompra"
                                  name="precioCompra"
                                  placeholder="0.00"
                                  value={formData.precioCompra}
                                  onChange={handleInputFormChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {formData.base && formData.base.pais && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="pais">
                              <FormattedMessage
                                id="llantasAbm.render.general_data.label_pais"
                                defaultMessage="Pais"
                              />
                              {requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">
                                {formData.base.pais.nombre}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-md-3 label-control col-form-label" htmlFor="odometro">
                          <FormattedMessage
                            id="llantasAbm.render.general_data.label_medidor"
                            defaultMessage="Medidor"
                          />
                          {requiredSymbol}:
                        </label>
                        <div className="col-md-9">
                          {props.action === 'VIEW' ? (
                            <div className="form-control-static col-form-label form-value">
                              {formData.odometro ? formData.odometro : ''}
                            </div>
                          ) : (
                            <div>
                              <input
                                type="number"
                                className="form-control"
                                id="odometro"
                                name="odometro"
                                placeholder="0.00"
                                value={formData.odometro}
                                onChange={handleInputFormChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* ANOTHER ROW */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label"
                            htmlFor="fechaAlta"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.vigencia.label_fecha_de_expedicion"
                              defaultMessage="Fecha de Alta"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {!formData.fechaAlta || formData.fechaAlta === '0000-00-00'
                                  ? ''
                                  : moment(formData.fechaAlta).format('L')}
                              </div>
                            ) : (
                              <div>
                                <DatePicker
                                  id="fechaAlta"
                                  name="fechaAlta"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={intl.formatMessage({
                                    id: 'llantasAbm.render.general_data.placeholder_fecha_de_expedicion',
                                    defaultMessage: 'DD/MM/AAAA',
                                  })}
                                  selected={
                                    !formData.fechaAlta || formData.fechaAlta === '0000-00-00'
                                      ? null
                                      : Timezone.getDateForServer(moment(formData.fechaAlta))
                                  }
                                  onChange={(event) =>
                                    handleSelectFormChange(event ? event : '', 'fechaAlta')
                                  }
                                  onChangeRaw={(event) =>
                                    handleSelectFormChange(event.target.value, 'fechaAlta')
                                  }
                                  openToDate={moment()}
                                  dateFormat="DD/MM/YYYY"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  autoComplete="off"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {formData.fechaAlta ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="fechaVenc"
                            >
                              <FormattedMessage
                                id="llantasAbm.render.vigencia.label_fechaVenc"
                                defaultMessage="Fecha de Vencimiento"
                              />
                              {requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {!formData.fechaVenc || formData.fechaVenc === '0000-00-00'
                                    ? ''
                                    : moment(formData.fechaVenc).format('L')}
                                </div>
                              ) : (
                                <div>
                                  <DatePicker
                                    id="fechaVenc"
                                    name="fechaVenc"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={intl.formatMessage({
                                      id: 'llantasAbm.render.general_data.placeholder_fechaVenc',
                                      defaultMessage: 'DD/MM/AAAA',
                                    })}
                                    selected={
                                      !formData.fechaVenc || formData.fechaVenc === '0000-00-00'
                                        ? null
                                        : Timezone.getDateForServer(moment(formData.fechaVenc))
                                    }
                                    onChange={(event) =>
                                      handleSelectFormChange(
                                        event ? event.format() : '',
                                        'fechaVenc',
                                      )
                                    }
                                    onChangeRaw={(event) =>
                                      handleSelectFormChange(event.target.value, 'fechaVenc')
                                    }
                                    minDate={moment.max([moment(), moment(formData.fechaAlta)])}
                                    openToDate={moment()}
                                    dateFormat="DD/MM/YYYY"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    autoComplete="off"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-md-3 label-control col-form-label" htmlFor="base">
                            <FormattedMessage
                              id="movilesAbm.render.general_data.label_base"
                              defaultMessage="Base"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.base && formData.base.descripcion}
                              </div>
                            ) : (
                              <div>
                                <LlantaBasesSelect
                                  value={formData.base}
                                  intl={intl}
                                  changeBase={(e) => handleSelectFormChange(e, 'base')}
                                  disabled={
                                    (formData.movil ? true : false) || props.action === 'VIEW'
                                  }
                                />
                                {/* <Select
                                  id="base"
                                  name="base"
                                  placeholder={intl.formatMessage({
                                    id: 'llantasAbm.render.general_data.placeholder_base',
                                    defaultMessage: 'Seleccione',
                                  })}
                                  options={bases}
                                  valueKey="id"
                                  labelKey="descripcion"
                                  value={formData.base}
                                  onChange={(e) => handleSelectFormChange(e, 'base')}
                                  disabled={
                                    (formData.movil ? true : false) ||
                                    props.action === 'VIEW'
                                    
                                  }
                                /> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {formData.base && formData.base.jefe && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="jefe_base"
                            >
                              <FormattedMessage
                                id="llantasAbm.render.general_data.label_jefe_de_base"
                                defaultMessage="Jefe de Base"
                              />{' '}
                              :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">
                                {formData.base.jefe.nombre + ' ' + formData.base.jefe.apellido}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {formData.base && formData.base.pais && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="pais">
                              <FormattedMessage
                                id="llantasAbm.render.general_data.label_pais"
                                defaultMessage="Pais"
                              />
                              {requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">
                                {formData.base.pais.nombre}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* SUB ROW */}
              <div className="card pull-up">
                <div className="card-content">
                  <div className="card-body">
                    <h4 className="form-section">
                      <i className="las la-user-circle"></i>{' '}
                      <FormattedMessage
                        id="llantasAbm.render.general_data.header_asignacion"
                        defaultMessage="Asignación"
                      />{' '}
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label"
                            htmlFor="vehiculo"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.general_data.label_movil_select"
                              defaultMessage="Vehiculo"
                            /> :
                          </label>
                          <div className="col-md-9">
                            {props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">
                                {formData.movil ? formData.movil.dominio : ''}
                              </div>
                            ) : (
                              <div>
                                <Select.Async
                                  placeholder={intl.formatMessage({
                                    id: 'LlantasAbm.render.general_data.placeholder_movil_select',
                                    defaultMessage: 'Seleccione',
                                  })}
                                  name="movil"
                                  value={formData.movil}
                                  valueKey="id"
                                  labelKey="dominio"
                                  loadOptions={getBackendMoviles}
                                  onChange={(e) => handleSelectFormChange(e, 'movil')}
                                  disabled={props.action === 'EDIT'}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {!repuestoSwitch ? (
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="posicion"
                            >
                              <FormattedMessage
                                id="llantasAbm.render.general_data.label_posicion"
                                defaultMessage="Posición"
                              /> :
                            </label>
                            <div className="col-md-9">
                              {props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {formData.posicion ? formData.posicion.posicion : ''}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="posicion"
                                    name="posicion"
                                    placeholder={
                                      formData.movil
                                        ? intl.formatMessage({
                                            id: 'llantasAbm.render.general_data.placeholder_posiciones',
                                            defaultMessage: 'Seleccione',
                                          })
                                        : ''
                                    }
                                    options={
                                      formData.movil &&
                                      (formData.movil.tipologiaPosicion
                                        ? formData.movil.tipologiaPosicion.llanta_posiciones
                                        : formData.movil.modelo.tipologiaPosicion.llanta_posiciones)
                                    }
                                    valueKey="id"
                                    labelKey="label"
                                    onChange={(e) => handleSelectFormChange(e, 'posicion')}
                                    value={formData.posicion}
                                    disabled={!formData.movil}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {/* <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label"
                            htmlFor="eje_flotante"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.vigencia.label_eje_flotante"
                              defaultMessage="Eje Flotante?"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            <Switch
                              onChange={(value) => handleEjeSwitchChange(value)}
                              checked={ejeSwitch}
                              disabled={props.action === 'VIEW'}
                              offColor="#FF4961"
                              onColor="#28D094"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="col-md-6">
                        {/* <div className="form-group row">
                          <label
                            className="col-md-3 label-control col-form-label"
                            htmlFor="auxilio"
                          >
                            <FormattedMessage
                              id="llantasAbm.render.vigencia.label_auxilio"
                              defaultMessage="Auxilio?"
                            />
                            {requiredSymbol}:
                          </label>
                          <div className="col-md-9">
                            <Switch
                              onChange={setRepuestoSwitch}
                              checked={repuestoSwitch}
                              disabled={props.action === 'VIEW'}
                              offColor="#FF4961"
                              onColor="#28D094"
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>

                    {/* ROW END */}
                  </div>
                </div>
              </div>
              {!props.hideBack && (
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                    {(ConfigBusiness.get('llantas.serial.autoasignacion.habilitado') === "false") ? 
                      <div className="text-cd text-right">
                        {props.action !== 'VIEW' && (
                          <button 
                            type="submit" 
                            className="btn btn-primary mr-1"
                            disabled={
                              formData.serial && 
                              formData.llanta_marca &&
                              formData.llanta_modelo &&
                              formData.recapadas &&
                              (bandaRequerida ? (formData.banda ? true : false ): true) &&
                              formData.titular &&
                              formData.precioCompra &&
                              (formData.odometro !== null && formData.odometro !== '' ) &&
                              formData.fechaAlta &&
                              formData.fechaVenc &&
                              formData.base
                              ? false : true }
                          >
                            <i className="fa fa-check-circle"></i>
                            <FormattedMessage
                              id="llantasAbm.finish_button.guardar"
                              defaultMessage=" Guardar"
                            />
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => setRedirectTo('/llantas')}
                        >
                          <i className="fa fa-times-circle"></i>{' '}
                          {props.action === 'VIEW'
                            ? intl.formatMessage({
                                id: 'llantasAbm.finish_button.volver',
                                defaultMessage: 'Volver',
                              })
                            : intl.formatMessage({
                                id: 'llantasAbm.finish_button.cancelar',
                                defaultMessage: 'Cancelar',
                              })}
                        </button>
                      </div> : 
                        <div className="text-cd text-right">
                        {props.action !== 'VIEW' && (
                          <button 
                            type="submit" 
                            className="btn btn-primary mr-1"
                            disabled={
                              formData.llanta_marca &&
                              formData.llanta_modelo &&
                              formData.recapadas &&
                              (bandaRequerida ? (formData.banda ? true : false ): true) &&
                              formData.titular &&
                              formData.precioCompra &&
                              (formData.odometro !== null && formData.odometro !== '' ) &&
                              formData.fechaAlta &&
                              formData.fechaVenc &&
                              formData.base
                              ? false : true }
                          >
                            <i className="fa fa-check-circle"></i>
                            <FormattedMessage
                              id="llantasAbm.finish_button.guardar"
                              defaultMessage=" Guardar"
                            />
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => setRedirectTo('/llantas')}
                        >
                          <i className="fa fa-times-circle"></i>{' '}
                          {props.action === 'VIEW'
                            ? intl.formatMessage({
                                id: 'llantasAbm.finish_button.volver',
                                defaultMessage: 'Volver',
                              })
                            : intl.formatMessage({
                                id: 'llantasAbm.finish_button.cancelar',
                                defaultMessage: 'Cancelar',
                              })}
                        </button>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LlantasAbm;
